var prodcat = prodcat || {};
var generic = generic || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  function initProduct($product) {
    var $productRatingAverage = $product.find('.js-elc-comparison-module__rating-average');
    var $productRatingStars = $product.find('.js-elc-comparison-module__rating-stars');
    var ratingAverageData = $productRatingAverage.attr('data-rating-average');
    var ratingPercentage = Math.round((ratingAverageData / 5) * 100);
    var $addToBag = $product.find('.js-elc-comparison-module__add-to-bag .js-elc-button');

    $productRatingStars.addClass('width-' + ratingPercentage);

    checkInventory(parseFloat($product.attr('data-sku-inventory-status')), $product);

    $addToBag.attr('href', 'javascript: void(0)');
    $addToBag.once().on('click', function(event) {
      var skuId = parseFloat($product.attr('data-sku-base-id'));
      if (!$addToBag.hasClass('elc-button--disabled')) {
        site.addToCart({skuBaseId: skuId});
      } else {
        event.preventDefault();
      }
    });
  }
  
  function formattedPrice($product) {
    var $priceCurrent = $product.find('.js-elc-comparison-module__price--current');
    var $priceOld = $product.find('.js-elc-comparison-module__price--old');
    var formattedPrice = $priceCurrent.attr('data-sku-formatted-price').replace(/^[USCA]+/, '');
    var rawPrice = formattedPrice.replace(/[^0-9,.]/gu, '');
    var formattedPriceHasDecimal = [',', '.'].includes(rawPrice?.substr(-3, 1));
    var localeStringOptions = formattedPriceHasDecimal
      ? {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
      : {};
    var $priceCurrentValue = parseFloat($priceCurrent.attr('data-sku-price')).toLocaleString(Drupal.settings.price_locale, localeStringOptions);
    var $priceOldValue = parseFloat($priceOld.attr('data-sku-price-old')).toLocaleString(Drupal.settings.price_locale, localeStringOptions);

    if ($priceCurrentValue === $priceOldValue || isNaN($priceOldValue)) {
      $priceOld.addClass('hidden');
    } else {
      $priceOld.text(formattedPrice.replace(/[-+]?\d{1,3}(?:[.,]\d{3})*(?:[.,]\d+)?/gu, $priceOldValue));
      $priceOld.removeClass('hidden');
    }
    $priceCurrent.text(formattedPrice.replace(/[-+]?\d{1,3}(?:[.,]\d{3})*(?:[.,]\d+)?/gu, $priceCurrentValue));
  }

  function initSkuDropdown($product) {
    var $skuSelect = $('.js-elc-comparison-module__sku-select', $product);
    var $selectWrapper = $('.js-elc-comparison-module__sku-select--wrapper', $skuSelect);
    var $selectHeader = $('.js-elc-comparison-module__sku-select--header', $skuSelect);
    var $options = $('.js-elc-comparison-module__sku-select--options', $skuSelect);
    var $optionElements = $('.js-elc-comparison-module__sku-select__item', $skuSelect);
    var $caret = $('.js-elc-comparison-module__arrow-icon', $skuSelect);

    if ($optionElements.length > 1) {
      $selectWrapper.once().on('click', function() {
        $options.toggle();
        $skuSelect.toggleClass('opened');
        $caret.toggleClass('opened');
      });

      $selectWrapper.on('keydown', function(event) {
        if (event.key === "Enter" || event.key === " ") {
          $options.toggle();
          $skuSelect.toggleClass('opened');
          $caret.toggleClass('opened');
        }
      });

      $(document).once().on('click', function(event) {
        if (!$skuSelect.is(event.target) && $skuSelect.has(event.target).length === 0) {
          $options.hide();
          $skuSelect.removeClass('opened');
          $caret.removeClass('opened');
        }
      });
    } else {
      $caret.hide();
    }

    $optionElements.once().on('click', function() {
      selectOption($(this));
    });

    $optionElements.each(function() {
      if ($(this).attr('data-sku-inventory-status') === '4') {
        $(this).hide();
      }
    });

    $options.on('keydown', function(event) {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        var focusedOption = document.activeElement;
        var optionsArray = $optionElements.toArray();
        var currentIndex = optionsArray.indexOf(focusedOption);
        let newIndex;

        if (event.key === "ArrowUp") {
          newIndex = currentIndex > 0 ? currentIndex - 1 : optionsArray.length - 1;
        } else {
          newIndex = currentIndex < optionsArray.length - 1 ? currentIndex + 1 : 0;
        }

        focusedOption.setAttribute("tabindex", "-1");
        focusedOption.setAttribute("aria-selected", "false");
        optionsArray[newIndex].setAttribute("tabindex", "0");
        optionsArray[newIndex].setAttribute("aria-selected", "true");
        optionsArray[newIndex].focus();
      } else if (event.key === "Enter" || event.key === " ") {
        var focusedOption = document.activeElement;
        selectOption($(focusedOption));
      }
    });

    function selectOption(option) {
      var $shadeSelect = option.closest('.js-elc-comparison-module__shade-select');

      if ($shadeSelect.length > 0) {
        var $shadeHeader = $shadeSelect.find('.js-elc-comparison-module__shade-select--header');
        var $shadeHeaderSwatch = $shadeHeader.find('.js-elc-comparison-module__shade-select--swatch');
        var $shadeHeaderName = $shadeHeader.find('.js-elc-comparison-module__shade-select--name');
    
        var newColor = option.attr('data-shade-hex');
        $shadeHeaderSwatch.css('background-color', newColor);
        $shadeHeaderName.text(option.text());
      } else {
        $selectHeader.text(option.text());
      }

      $options.hide();
      $skuSelect.removeClass('opened');
      $caret.removeClass('opened');      
      skuChange(option);
    }
    
    function skuChange($option) {
      var $moduleContent = $option.parents('.js-elc-comparison-module__content');
      var $optionImage = $moduleContent.find('.js-elc-comparison-module__image');
      var $optionPrice = $moduleContent.find('.js-elc-comparison-module__price--current');
      var $optionPriceOld = $moduleContent.find('.js-elc-comparison-module__price--old');

      $option.closest('.js-elc-comparison-module__sku-select').attr('data-sku-base-id', $option.attr('data-sku-base-id'));
      $moduleContent.attr('data-sku-base-id', $option.attr('data-sku-base-id'));

      if ($skuSelect.hasClass('js-elc-comparison-module__shade-select')) {
        $option.closest('.js-elc-comparison-module__shade-select').attr('data-shade-hex', $option.attr('data-shade-hex'));
        $option.closest('.js-elc-comparison-module__shade-select').attr('data-shade-name', $option.attr('data-shade-name'));
      }

      $option.closest('.js-elc-comparison-module__sku-select').attr('data-sku-inventory-status', $option.attr('data-sku-inventory-status'));
      $moduleContent.attr('data-sku-inventory-status', $option.attr('data-sku-inventory-status'));

      $optionImage.attr('src', $option.attr('data-sku-image'));

      $optionPrice.attr('data-sku-price', $option.attr('data-sku-price'));
      $optionPrice.attr('data-sku-formatted-price', $option.attr('data-sku-formatted-price'));
      $optionPriceOld.attr('data-sku-price-old', $option.attr('data-sku-price-old'));
      formattedPrice($moduleContent);
      checkInventory(parseFloat($option.attr('data-sku-inventory-status')), $moduleContent);
    }
  }

  function checkInventory(status, $product) {
    var $addToBag = $product.find('.js-elc-comparison-module__add-to-bag .js-elc-button');
    if (status === 1 || status === 2) {
      $addToBag.removeClass('elc-button--disabled');
    } else if (!$addToBag.hasClass('disabled') && (status !== 1 || status !== 2)) {
      $addToBag.addClass('elc-button--disabled');
    }
  }

  function initCarousel($productCarousel, hasPinned) {
    $($productCarousel).slick({
      slidesToShow: hasPinned ? 3 : 4,
      arrows: false,
      infinite: false,
      dots: false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1559,
          settings: {
            slidesToShow: hasPinned ? 2 : 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: hasPinned ? 1 : 2
          }
        }
      ]
    });
  }

  function initRatings($products) {
    new Promise((resolve, reject) => {
      var url = Drupal.settings.power_reviews.snippet_url;
      var apiKey = Drupal.settings.power_reviews.api_key;
      var merchantId = Drupal.settings.power_reviews.merchant_id;
      var language = Drupal.settings.power_reviews.locale;
      var productIds = $products.toArray().map((product) => $(product).attr('data-product-id')).join(',').replaceAll('PROD', '');

      url = url.replace('MERCHANT_ID', merchantId)
        .replace('PRODUCT_IDS', productIds)
        .replace('API_KEY', apiKey)
        .replace('LOCALE_LANGUAGE', language);
      $.ajax(url)
        .done(function (r) {
          resolve(r);
        })
        .fail(function (e) {
          reject(e);
        });
    }).then((data) => {
      $products.each(function () {
        var $product = $(this);
        var $productRatingAverage = $product.find('.js-elc-comparison-module__rating-average');
        var $productRatingStars = $product.find('.js-elc-comparison-module__rating-stars');
        var $productRatingTotal = $product.find('.js-elc-comparison-module__rating-total');
        var productId = $product.attr('data-product-id').replaceAll('PROD', '');
        var reviewsData = data.results.filter((review) => (
          parseInt(review.page_id) === parseInt(productId) && review.rollup
        ));
        var averageRating = 0;

        if (reviewsData.length > 0) {
          averageRating = reviewsData[0].rollup.average_rating || 0;
          $productRatingStars.addClass('width-' + Math.round((averageRating / 5) * 100));
          $productRatingAverage.attr('data-rating-average', averageRating);
          $productRatingTotal.text(`(${reviewsData[0].rollup.review_count})`);
        }
        if (!$productRatingAverage.attr('data-rating-average')) {
          $productRatingAverage.addClass('hidden');
          $productRatingTotal.addClass('hidden');
        }
      });
    });
  }

  Drupal.behaviors.comparisonModule = {
    attach: function (context) {
      var $moduleWrapper = $('.js-elc-comparison-module--v1', context);
      var $products = $('.js-elc-comparison-module__content', $moduleWrapper);

      $moduleWrapper.each(function () {
        var $this = $(this);
        var $pinnedWrapper = $('.js-elc-comparison-module__products-pinned', $this);
        var hasPinned = $pinnedWrapper.length > 0;
        var $productCarousel;
        var $pinnedProduct;
        var $pinnedLink;

        if (hasPinned) {
          $pinnedProduct = $('.js-elc-comparison-module__content', $this).first();
          $pinnedLink = $pinnedProduct.find('.js-elc-comparison-module__link');
          $productCarousel = $('.js-elc-comparison-module__products-carousel', $this);

          $pinnedProduct.appendTo($pinnedWrapper);
          $pinnedLink.attr('href', '#').addClass('disabled');
        } else {
          $productCarousel = $('.js-elc-comparison-module__products-carousel--full', $this);
        }
        initCarousel($productCarousel, hasPinned);
      });
      $products.each(function () {
        var $this = $(this);

        initProduct($this);
        initSkuDropdown($this);
        formattedPrice($this);
      });
      initRatings($products);
    }
  };
})(jQuery);
